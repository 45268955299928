.span-footer-text {
  color: var(--iq-black);
  font-size: 14px !important;
}
.footer-mobile__item {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}
.footer-mobile__item--icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: transparent;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.footer-mobile__item--icon img {
  max-width: 100%;
  height:35px;
  width: 35px;
}

.footer-mobile__item--count {
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  border: 2px solid var(--iq-yellow);
  border-radius: 50%;
  top: 0px;
  right: -10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 10px !important;
}

.footer-mobile__item--count span {
  color: var(--iq-white);
  margin-top: 3px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
html[lang="ar"] .footer-mobile__item--count{
    left: -10px;  
}

@media (max-width: 992px) {
  .span-footer-text {
    white-space: nowrap;
  }
}
@media (max-width: 575px) {
  .span-footer-text {
    font-size: 11px !important;
  }
  .footer-mobile__item--icon img {
    height:32px;
    width: 32px;
  }
}