.footer-mobile {
  padding: 5px 0px 10px;
  background-color: var(--iq-white);
  border-top: 1px solid var(--iq-background);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.footer-mobile__content ul {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.footer-mobile__content ul li {
  list-style: none;
  width: 25%;
  text-align: center;
}

.footer-mobile__content ul li:not(:last-child) {
  margin-right: auto;
}

.footer-mobile__content ul li:nth-child(3) a {
  font-size: 11px;
}

.footer-mobile__content ul li a {
  font-size: 11px;
  color: var(--iq-orange);
  line-height: normal;
}

.page-rtl .footer-mobile__content ul li:not(:last-child) {
  margin-right: 0;
  margin-left: auto;
}

.skin_427.page-ml .footer-mobile__content ul li a,
.skin_449.page-ml .footer-mobile__content ul li a {
  font-size: 10px;
  text-align: center;
}

@media (min-width: 992px) {
  .footer-mobile {
    display: none;
  }
}
