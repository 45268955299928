.header__cart:hover .header__cart-alert {
  display: block;
}

.header__cart-alert {
  position: absolute;
  display: none;
  top: 52px;
}

i.fa.fa-caret-up {
  position: absolute;
  left: 7px;
  top: -16px;
  font-size: 30px;
  color: var(--iq-pink);
}

.header__cart-alert--wrapper {
  position: relative;
  display: -webkit-box;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 0px 2px;
  border-radius: 5px;
  width: 200px;
  background-color: var(--iq-pink);
  z-index: 3;
}

.header__cart-alert img:first-child {
  width: 20px;
  position: absolute;
  left: 10px;
  top: -20px;
}

.header__cart-alert img:nth-child(2) {
  width: 45px;
  margin-right: 20px;
}

.header__cart-alert p,
.header__cart-alert a {
  color: var(--iq-white);
  font-size: 14px !important;
}

.header__cart-alert a:hover {
  color: #fee556;
}

html[lang="ar"] i.fa.fa-caret-up {
  right: 7px !important;
  left: auto;
}